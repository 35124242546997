import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query ContatoQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title_seo
        description_seo
        contato_email
        contato_tel
        contato_endereco1
        contato_endereco2
      }
    }
  }
`

const Contato = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const mailto = "mailto:" + frontmatter.contato_email

  return (
    <Layout>
      <SEO 
        title={frontmatter.title_seo}
        description={frontmatter.description_seo} 
      />
      <section className="entre-em-contato top-espaco">
        <div className="container">
          <h2>ENTRE EM CONTATO</h2>
          <div className="col-md-6">
            <form 
              className="contact-form" 
              action="/contato-resposta" 
              name="formulario-contato-site" 
              method="POST" 
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="formulario-contato-site" />
              <div className="form-group">
                <label htmlFor="nome">Nome *</label>
                <input type="text" id="nome" name="nome" placeholder="Nome completo" required className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="email">E-mail *</label>
                <input type="email" id="email" name="replyto" placeholder="email@email.com" required className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="mensagem">Sua Dúvida ou Mensagem *</label>
                <textarea id="mensagem" name="mensagem" placeholder="Digite sua dúvida" required className="form-control"></textarea>
              </div>
              <button type="submit" className="btn btn-enviar">ENVIAR</button>
              <a className="btn btn-enviar link-whats" target="_blank" rel="noreferrer" href="https://web.whatsapp.com/send?phone=5524988548895">
                <img src="../assets/whatsapp.svg" alt="icone estudos urbanos" /> Fale Conosco pelo Whatsapp
              </a>
            </form>
          </div>            
          <div className="col-md-6">
            <ul className="info hidden-xs">
              <li>
                <i className="fa fa-envelope"></i>
                <a href={mailto}>{frontmatter.contato_email}</a>
              </li>                    
              <li>
                <i className="fa fa-map-marker"></i>
                <div style={{whiteSpace: 'pre'}}>{frontmatter.contato_endereco1}</div>
              </li>
              <li>
                <i className="fa fa-map-marker"></i>
                <div style={{whiteSpace: 'pre'}}>{frontmatter.contato_endereco2}</div>
              </li>
              <li>
                <i className="fa fa-phone"></i>{frontmatter.contato_tel}
              </li>
            </ul>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default Contato